<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('dae_subsidy.farmer_name_en') }}</b-th>
                                        <b-td> {{ billInfo.farmer_name }} </b-td>
                                        <b-th>{{ $t('dae_subsidy.farmer_name_bn') }}</b-th>
                                        <b-td> {{ billInfo.farmer_name_bn }} </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('common_config.mobile_no') }}</b-th>
                                        <b-td> {{ billInfo.mobile_no }} </b-td>
                                        <b-th>{{ $t('common_config.nid_no') }}</b-th>
                                        <b-td> {{ billInfo.nid_no }} </b-td>
                                    </b-tr>
                                     <b-tr>
                                        <b-th>{{ $t('dae_config.instrument_name') }}</b-th>
                                        <b-td> {{ billInfo.instrument_name }} </b-td>
                                        <b-th>{{ $t('dae_subsidy.actual_price') }}</b-th>
                                        <b-td> {{ billInfo.actual_price }} </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('dae_subsidy.subsidized_price') }}</b-th>
                                        <b-td> {{ billInfo.subsidized_price }} </b-td>
                                        <b-th>{{ $t('dae_subsidy.farmer_paid') }}</b-th>
                                        <b-td> {{ billInfo.farmer_paid }} </b-td>
                                    </b-tr>
                                     <b-tr>
                                        <b-th>{{ $t('dae_subsidy.remaining') }}</b-th>
                                        <b-td> {{ billInfo.remaining }} </b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                            <b-col lg="12" sm="12">
                                <h4>{{ $t('budget.forwardComments') }}</h4>
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th>{{ $t('budget.sendFrom') }}</b-th>
                                        <b-th>{{ $t('budget.sendTo') }}</b-th>
                                        <b-th>{{ $t('budget.comment') }}</b-th>
                                    </b-tr>
                                    <b-tr v-for="(forward,index) in billInfo.forward" :key="index">
                                        <b-td>{{ $n(index+1) }}</b-td>
                                        <b-td>{{ getUserName(forward.sender_id) }}</b-td>
                                        <b-td>{{ getUserName(forward.receiver_id) }}</b-td>
                                        <b-td>{{ forward.comment }}</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
export default {
    props: ['item'],
    data () {
        return {
            loading: false,
            billInfo: '',
            search: {},
            users: []
        }
    },
    created () {
        this.billInfo = this.item
        this.getForwardedUsers(this.billInfo.forward)
    },
    computed: {
    },
    methods: {
        async getForwardedUsers (forwards) {
            this.loading = true
            var userIds = []
            forwards.map((item, key) => {
                userIds.push(item.sender_id)
                userIds.push(item.receiver_id)
            })

            const params = Object.assign({}, this.search, { user_ids: userIds })
            await RestApi.getData(authServiceBaseUrl, '/user/user-list', params).then(response => {
                if (response.success) {
                    this.users = response.data
                } else {
                    this.users = []
                }
                this.loading = false
            })
        },
        getStatus (status) {
            if (status === 1) {
                return this.$t('budget.pending')
            } else if (status === 2) {
                return this.$t('budget.forward')
            } else if (status === 3) {
                return this.$t('budget.approve')
            } else if (status === 4) {
                return this.$t('budget.reject')
            }
        },
        getUserName (userId) {
            const user = this.users.find(user => user.value === userId)
            return user !== undefined ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
        }
    }
}
</script>
